.dashboard-main{
    width: auto;
    height: 100vh;
    background: #F5F6FE;
    overflow:auto;

    padding-left: 250px;
    
}

section {
    
    padding: 100px 40px 40px 40px;
}

@media screen and (min-width: 480px) {
    #leftsidebar {width: 200px; float: left;}
    #main {margin-left: 216px;}
  }