

.dasboardSidebar-container {
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 20px;
    width: 250px;
    height: 100%;
    z-index: 10;
    background-color: #fff;
    border-right: 1px solid #e7e9ed;

    box-shadow: rgb(0 0 0 / 5%) 0px 8px 15px;
    
}



a {
    text-decoration: none;
    color: #252930;
}

li {
    list-style: none;
}

.sidebar-logo {
    max-width: 100%;
    width: 170px;
    height: auto;
}

.dasboardSidebar-top {
    display: flex;
    flex-direction: column;
    gap: 40px;
    
}

.topName {
    text-transform: capitalize;
}

.dashboardSidebar-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sidebar-icons{
    margin-right: 15px;
    font-size: 20px;
    color: #252930;
}

.dasboardSidebar-buttom {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: fixed;
    bottom: 0;
    margin-bottom: 20px;
}

.dasboardSidebar-startups {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

/* Dashboard Navbar  */

.dashboardNavbar {
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border-bottom: 1px solid #e7e9ed;
    height: auto;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    padding-left: 250px;

}
.dashboardNavbar-container{
    display: flex;
    margin: 10px 40px;
    align-items: center;justify-content: space-between;
    
}

.dashboardNavbar-links {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}


.dashboardNavbar-links>img {
    width: 36px;
    height: auto;
    border-radius: 50%;
}

.navbar-icons {
    font-size: 25px;
    color: #252930;
}

.dashboradNavbar-search {
    position: relative;
    width: 50%;
    display: flex;
    align-items: center;
}

.navbar-search-icon{
    position: absolute;
    right: 20px;
    font-size: 15px;
    color: #252930;
    cursor: pointer;
}

.navbar-search-form {
    padding-top: 0.6rem 0;
    height: 2.5rem;
    border: 1px solid#e7e9ed;
    color: #5d6778;
    font-size: .875rem;
    border-radius: 0.25rem;
    padding-right: 3rem;
    padding-left: 1rem;
    width: 100%;
}
