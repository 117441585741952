.overview-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.overview_card {
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
    padding: 20px;
    gap: 25px;
}

.loading {
    font-size: 20px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 900;
    font-style: italic;
    text-align: center;
    align-items: center;
}

.overview-title  h1{
    font-size: 1.5rem !important;
    font-weight: bolder;
    margin-bottom: 1.5rem;
}

.isVerifiedTT p {
    color: #15a362;
    font-size: 1.5rem !important;
    font-weight: bolder;
    margin-bottom: 1.5rem;
}

.overview_card_title{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.overview_card_title h2 {
    font-size: 1.125rem;
}


.overview_card_title span {
    display: inline-block;
    background: #edfdf6;
    color: #15a362;
    width: 50px;
    height: 50px;
    padding-top: 10px;
    font-size: 1rem;
    text-align: center;
    border-radius: 50%;
}

.overview-card-icon {
    font-size: 1rem;
}
.card-readmore{
    background: #fff;
    color: #5d6778;
    border: 1px solid #bcc1cb;
    font-size: 13px;
    font-weight: 500;
    padding: 10px 20px;
    margin-top: 30px;
    text-decoration: none;
    border-radius: 5px;

}

    