.startup-d-container {
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
    padding: 20px 20px 0 20px;
}

.startup-d-header {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 40px 0;
    border-bottom: 1px solid rgb(219, 219, 222);
    padding-bottom: 20px;
}

.loading {
    font-size: 20px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 900;
    font-style: italic;
    text-align: center;
    align-items: center;
}



.startup-category {
    background-color: #0d6efd;
    display: inline;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    padding: 5px 10px;
    border-radius: 5px;
}
 
.startup-name{
    margin-top: 10px;
    font-size: 25px;
}

.startup-founder-date{
    margin-top: 10px;
    color: rgb(172, 172, 173);
    font-size: 15px;
}

.startup-d-contact {
    background-color: #fafbff;
    
}

.startup-d-contact a {
    margin: 20px 0;
    color: green;
}