/*===========================
    2.HEADER css 
===========================*/
.appie-sticky.sticky {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1010;
    background: #fff;
    box-shadow: 0 15px 30px 0 #0000000f;
    padding-top: 20px;
    padding-bottom: 20px;
    animation: sticky  1.2s; }
  
  .appie-header-area {
    padding-top: 30px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999; }
    .appie-header-area.appie-header-2-area .appie-btn-box .main-btn {
      background: #ff3e66;
      border-radius: 30px;
      border-color: #ff3e66; }
      .appie-header-area.appie-header-2-area .appie-btn-box .main-btn:hover {
        background: transparent;
        color: #ff3e66; }
    .appie-header-area.appie-header-3-area.appie-sticky.sticky {
      background: #0e1133; }

      
    .appie-header-area.appie-header-4-area.appie-sticky.sticky {
      background: linear-gradient(90deg, #6b1fcd 0%, #374ede 100%); }
    .appie-header-area.appie-header-page-area.appie-sticky.sticky {
      background: #0a44b9; }

    .appie-header-area.appie-header-7-area.appie-sticky.sticky {
      background: #290645; }

    .appie-header-area.appie-header-error-page {
      box-shadow: 0px 10px 20px 0px rgba(14, 17, 51, 0.1);
      padding-top: 20px;
      padding-bottom: 20px; }
  
  .header-nav-box .appie-btn-box {
    position: relative; }
    
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-nav-box .appie-btn-box {
        padding-right: 50px; } }
    @media (max-width: 767px) {
      .header-nav-box .appie-btn-box {
        padding-right: 30px; } }
    .header-nav-box .appie-btn-box .login-btn {
      color: #0e1133;
      font-size: 15px;
      font-weight: 500; 
    }

    .login-btn-white {
      color: #fff;
      font-size: 15px;
      font-weight: 500; 
    }
      .header-nav-box .appie-btn-box .login-btn i {
        padding-right: 6px; }
    @media (max-width: 767px) {
      .header-nav-box .appie-btn-box .main-btn {
        display: none; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .header-nav-box .appie-btn-box .main-btn {
        padding: 0 10px;
        display: inline-block; } }
  .header-nav-box.header-nav-box-3 .appie-header-main-menu ul > li > a {
    color: #fff; }
  .header-nav-box.header-nav-box-3 .appie-header-main-menu ul > li .sub-menu li a {
    color: #0e1133; }
  .header-nav-box.header-nav-box-3 .appie-btn-box .login-btn {
    color: #fff; }
  .header-nav-box.header-nav-box-3 .appie-btn-box .main-btn {
    background: #ff6b58;
    border-color: #ff6b58; }
    .header-nav-box.header-nav-box-3 .appie-btn-box .main-btn:hover {
      background: transparent;
      color: #ff6b58; }
  .header-nav-box.header-nav-box-3 .toggle-btn {
    color: #fff;
    font-size: 20px; }
  .header-nav-box.header-nav-box-3.header-nav-box-inner-page .appie-btn-box .main-btn {
    border-color: #fff;
    background: transparent; }
    .header-nav-box.header-nav-box-3.header-nav-box-inner-page .appie-btn-box .main-btn:hover {
      background: #fff;
      color: #2b70fa; }
  .header-nav-box.header-nav-box-5 .appie-header-main-menu ul > li > a {
    color: #fff; }
  .header-nav-box.header-nav-box-5 .appie-header-main-menu ul > li .sub-menu li a {
    color: #0e1133; }
  .header-nav-box.header-nav-box-5 .appie-btn-box .login-btn {
    color: #fff; }
  .header-nav-box.header-nav-box-5 .appie-btn-box .main-btn {
    background: #f84a6e;
    border-color: #f84a6e; }
    .header-nav-box.header-nav-box-5 .appie-btn-box .main-btn:hover {
      background: transparent;
      color: #f84a6e; }
  .header-nav-box.header-nav-box-5 .toggle-btn {
    color: #fff;
    font-size: 20px; }
  .header-nav-box.header-nav-4-box .appie-header-main-menu ul > li > a:hover {
    color: #801f82; }
  .header-nav-box.header-nav-4-box .appie-btn-box .main-btn {
    background: #801f82;
    border-color: #801f82; 
  }
    

    .header-nav-box.header-nav-4-box .appie-btn-box .main-btn:hover {
      background: transparent;
      color: #801f82; }
  @media (max-width: 767px) {
    .header-nav-box.header-nav-4-box .appie-btn-box ul {
      margin-right: 10px; } }
  .header-nav-box.header-nav-4-box .appie-btn-box ul li {
    display: inline-block; }
    .header-nav-box.header-nav-4-box .appie-btn-box ul li a {
      font-size: 18px;
      color: #0e1133;
      margin-left: 15px; }
      .header-nav-box.header-nav-4-box .appie-btn-box ul li a.cart-btn {
        position: relative; }
        .header-nav-box.header-nav-4-box .appie-btn-box ul li a.cart-btn span {
          position: absolute;
          top: -7px;
          display: inline-block;
          right: -12px;
          height: 20px;
          width: 20px;
          text-align: center;
          line-height: 20px;
          color: #fff;
          font-size: 11px;
          background: #801f82;
          border-radius: 50%; }
  .header-nav-box.header-nav-box-6 .main-btn {
    background: #9b2cfa;
    border-color: #9b2cfa; }
    .header-nav-box.header-nav-box-6 .main-btn:hover {
      background: transparent;
      color: #9b2cfa; }
  .header-nav-box.header-nav-box-6 .appie-header-main-menu ul > li > a:hover {
    color: #9b2cfa; }
  .header-nav-box.header-nav-box-6 .appie-btn-box form {
    display: inline-block;
    margin-left: 18px; }
    @media (max-width: 767px) {
      .header-nav-box.header-nav-box-6 .appie-btn-box form {
        display: none; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .header-nav-box.header-nav-box-6 .appie-btn-box form {
        display: inline-block; } }
    .header-nav-box.header-nav-box-6 .appie-btn-box form .input-box {
      position: relative; }
      .header-nav-box.header-nav-box-6 .appie-btn-box form .input-box i {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%); }
      .header-nav-box.header-nav-box-6 .appie-btn-box form .input-box input {
        width: 105px;
        padding-left: 50px;
        border: 0;
        border-left: 1px solid #ddddea;
        background: transparent; }
  .header-nav-box.header-nav-box-7 .appie-header-main-menu ul > li > a {
    color: #fff; }
    .header-nav-box.header-nav-box-7 .appie-header-main-menu ul > li > a:hover {
      color: #2b70fa; }
  .header-nav-box.header-nav-box-7 .appie-header-main-menu ul > li .sub-menu li a {
    color: #505056; }
    .header-nav-box.header-nav-box-7 .appie-header-main-menu ul > li .sub-menu li a:hover {
      color: #2b70fa; }
  .header-nav-box.header-nav-box-7 .appie-btn-box .login-btn {
    color: #fff; }
  .header-nav-box.header-nav-box-7 .appie-btn-box .toggle-btn {
    color: #fff; }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-header-main-menu {
      display: none; } }
  @media (max-width: 767px) {
    .appie-header-main-menu {
      display: none; } }
  .appie-header-main-menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .appie-header-main-menu ul > li {
      display: inline-block;
      margin-right: 30px;
      position: relative; }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .appie-header-main-menu ul > li {
          margin-right: 14px; } }


      .appie-header-main-menu ul > li > a {
        font-size: 15px;
        font-weight: 500;
        color: #0e1133;
        line-height: 45px; 
      }

      .navWhite  ul > li >a {
        color: white!important;
      }

        .appie-header-main-menu ul > li > a i {
          padding-left: 6px; }
          
      .appie-header-main-menu ul > li .sub-menu {
        position: absolute;
        left: 0;
        top: 110%;
        width: auto;
        min-width: 220px;
        max-width: 220px;
        background-color: #fff;
        opacity: 0;
        visibility: hidden;
        transition: all linear 0.3s;
        z-index: 99;
        -webkit-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
        -moz-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
        box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
        list-style-type: none;
        margin: 0;
        padding: 15px 0;
        border-radius: 5px;
        text-align: left; }
        @media only screen and (min-width: 1200px) and (max-width: 1600px) {
          .appie-header-main-menu ul > li .sub-menu {
            min-width: 200px;
            max-width: 200px; } }
        @media only screen and (min-width: 992px) and (max-width: 1200px) {
          .appie-header-main-menu ul > li .sub-menu {
            min-width: 200px;
            max-width: 200px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .appie-header-main-menu ul > li .sub-menu {
            position: relative;
            width: 100%;
            left: 0;
            top: auto;
            opacity: 1;
            visibility: visible;
            display: none;
            right: auto;
            -webkit-transform: translateX(0%);
            -moz-transform: translateX(0%);
            -ms-transform: translateX(0%);
            -o-transform: translateX(0%);
            transform: translateX(0%);
            -webkit-transition: all none ease-out 0s;
            -moz-transition: all none ease-out 0s;
            -ms-transition: all none ease-out 0s;
            -o-transition: all none ease-out 0s;
            transition: all none ease-out 0s;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            text-align: left;
            border-top: 0;
            transition: 0s;
            padding: 0; } }
        @media (max-width: 767px) {
          .appie-header-main-menu ul > li .sub-menu {
            position: relative;
            width: 100%;
            left: 0;
            top: auto;
            opacity: 1;
            visibility: visible;
            display: none;
            right: auto;
            -webkit-transform: translateX(0%);
            -moz-transform: translateX(0%);
            -ms-transform: translateX(0%);
            -o-transform: translateX(0%);
            transform: translateX(0%);
            -webkit-transition: all none ease-out 0s;
            -moz-transition: all none ease-out 0s;
            -ms-transition: all none ease-out 0s;
            -o-transition: all none ease-out 0s;
            transition: all none ease-out 0s;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            text-align: left;
            border-top: 0;
            transition: 0s;
            padding: 0; } }
        .appie-header-main-menu ul > li .sub-menu > li {
          position: relative;
          margin-left: 0;
          display: block; }
          .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
            margin-left: 0px; }
            @media only screen and (min-width: 768px) and (max-width: 991px) {
              .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
                margin-left: 0; } }
            @media (max-width: 767px) {
              .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
                margin-left: 0; } }
          .appie-header-main-menu ul > li .sub-menu > li .sub-nav-toggler {
            color: #0e1133;
            -webkit-transition: all 0.3s ease-out 0s;
            -moz-transition: all 0.3s ease-out 0s;
            -ms-transition: all 0.3s ease-out 0s;
            -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s; }
          .appie-header-main-menu ul > li .sub-menu > li a {
            display: block;
            padding: 0px 30px;
            position: relative;
            color: #505056;
            -webkit-transition: all 0.3s ease-out 0s;
            -moz-transition: all 0.3s ease-out 0s;
            -ms-transition: all 0.3s ease-out 0s;
            -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;
            border-radius: 4px;
            margin: 0 0;
            line-height: 2.5; }
            @media only screen and (min-width: 1200px) and (max-width: 1600px) {
              .appie-header-main-menu ul > li .sub-menu > li a {
                padding: 0 20px; } }
            @media only screen and (min-width: 992px) and (max-width: 1200px) {
              .appie-header-main-menu ul > li .sub-menu > li a {
                padding: 0 20px; } }
            .appie-header-main-menu ul > li .sub-menu > li a i {
              float: right;
              font-size: 16px;
              margin-top: 10px; }
              @media only screen and (min-width: 768px) and (max-width: 991px) {
                .appie-header-main-menu ul > li .sub-menu > li a i {
                  display: none; } }
              @media (max-width: 767px) {
                .appie-header-main-menu ul > li .sub-menu > li a i {
                  display: none; } }
            .appie-header-main-menu ul > li .sub-menu > li a .sub-nav-toggler i {
              display: inline-block; }
            .appie-header-main-menu ul > li .sub-menu > li a:hover {
              padding-left: 35px; }
          .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
            right: auto;
            left: 100%;
            top: 50%;
            opacity: 0;
            visibility: hidden;
            -webkit-transition: all 0.3s ease-out 0s;
            -moz-transition: all 0.3s ease-out 0s;
            -ms-transition: all 0.3s ease-out 0s;
            -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s; }
            @media only screen and (min-width: 768px) and (max-width: 991px) {
              .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
                padding-left: 30px;
                -webkit-transition: all 0s ease-out 0s;
                -moz-transition: all 0s ease-out 0s;
                -ms-transition: all 0s ease-out 0s;
                -o-transition: all 0s ease-out 0s;
                transition: all 0s ease-out 0s; } }
            @media (max-width: 767px) {
              .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
                padding-left: 30px;
                -webkit-transition: all 0s ease-out 0s;
                -moz-transition: all 0s ease-out 0s;
                -ms-transition: all 0s ease-out 0s;
                -o-transition: all 0s ease-out 0s;
                transition: all 0s ease-out 0s; } }
            .appie-header-main-menu ul > li .sub-menu > li .sub-menu li {
              position: relative; }
              .appie-header-main-menu ul > li .sub-menu > li .sub-menu li .sub-menu {
                right: auto;
                left: 100%;
                top: 50%;
                opacity: 0;
                visibility: hidden;
                -webkit-transition: all 0.3s ease-out 0s;
                -moz-transition: all 0.3s ease-out 0s;
                -ms-transition: all 0.3s ease-out 0s;
                -o-transition: all 0.3s ease-out 0s;
                transition: all 0.3s ease-out 0s; }
              .appie-header-main-menu ul > li .sub-menu > li .sub-menu li:hover .sub-menu {
                top: 0%;
                opacity: 1;
                visibility: visible; }
          .appie-header-main-menu ul > li .sub-menu > li:hover .sub-menu {
            top: 0%;
            opacity: 1;
            visibility: visible; }
          .appie-header-main-menu ul > li .sub-menu > li:hover .sub-nav-toggler {
            color: #2b70fa; }
          .appie-header-main-menu ul > li .sub-menu > li:hover > a {
            color: #2b70fa; }
      .appie-header-main-menu ul > li:hover .sub-menu {
        opacity: 1;
        visibility: visible;
        top: 100%; }
  
  @-webkit-keyframes sticky {
    0% {
      top: -200px; }
    100% {
      top: 0; } }
  @keyframes sticky {
    0% {
      top: -200px; }
    100% {
      top: 0; } }
  .appie-header-8-area .header-nav-box .appie-btn-box .main-btn {
    background: #db0f30;
    border-color: #db0f30; }
    .appie-header-8-area .header-nav-box .appie-btn-box .main-btn:hover {
      background: #fff;
      color: #db0f30; }
  .appie-header-8-area .header-nav-box .appie-header-main-menu ul > li a:hover {
    color: #db0f30; }