.phonetype input[type="tel"]{
    width: 100%;
    height: 56px;
    font-size: 14px;
    line-height: 56px;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    background: #f5f5f7;
    color: #7e7c87;
    outline: none;
    border: 1px solid transparent;
    padding: 0 30px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.signup {
    width: 100%;
    height: 56px;
    font-size: 14px;
    line-height: 56px;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    background: #f5f5f7;
    color: #7e7c87;
    outline: none;
    border: 1px solid transparent;
    padding: 0 30px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.signup-bg {
    background-image: url(../assets/images/login/sign.jpg);
    /* background-image: url(./assets/images/hero-bg.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 180px;
    padding-bottom: 0px;
    overflow: visible;
    z-index: 15; 
}