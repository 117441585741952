.login-bg {
    background-image: url(../assets/images/login/login.jpg);
    /* background-image: url(./assets/images/hero-bg.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 180px;
    padding-bottom: 0px;
    overflow: visible;
    z-index: 15; 
}