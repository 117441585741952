.startup-d-container {
    display: grid;
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
    padding: 20px 20px 0 20px;
}

.startup-d-header {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 40px 0;
    border-bottom: 1px solid rgb(219, 219, 222);
    padding-bottom: 20px;
}

.phoneNumber {
    padding: 20px;
}



.startup-category {
    background-color: #0d6efd;
    display: inline;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    padding: 5px 10px;
    border-radius: 5px;
}
 
.startup-name{
    margin-top: 10px;
    font-size: 19px;
}

.startup-founder-date{
    margin-top: 10px;
    color: rgb(172, 172, 173);
    font-size: 15px;
}

.startup-d-contact {
    background-color: #fafbff;
    
}

.startup-d-contact a {
    margin: 20px 0;
    color: green;
}

.phonetype input[type="tel"]{
    width: 100%;
    height: 56px;
    font-size: 14px;
    line-height: 56px;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    background: #f5f5f7;
    color: #7e7c87;
    outline: none;
    border: 1px solid transparent;
    padding: 0 30px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.rolesT select{
    width: 100%;
    height: 56px;
    font-size: 14px;
    line-height: 56px;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    background: #f5f5f7;
    color: #7e7c87;
    outline: none;
    border: 1px solid transparent;
    padding: 0 30px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}
.react-date-picker__wrapper {
    width: 100%;
    height: 56px;
    font-size: 14px;
    line-height: 56px;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    background: #f5f5f7;
    color: #7e7c87;
    outline: none;
    border: 1px solid transparent;
    padding: 0 30px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}