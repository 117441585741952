.required:after {
    content:" *";
    color: red;
  }

  .loading {
    font-size: 20px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 900;
    font-style: italic;
    text-align: center;
    align-items: center;
} 